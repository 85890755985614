<template>
  <ResizableColumnContainer
    v-if="canManageTags"
    :maxCol="4"
    @total-size="onTotalSize"
  >
    <resizable-column
      v-show="Tagsmostralista"
      :key="componentKey"
      :columnIndex="0"
      @column-resize="campaignColumnResize"
      ref="list"
      :className="`flex-container flex-col layout-content w10 innovativestudio_colonne`"
    >
      <TagsList
        :tags="tags"
        :selectedId="route.params.id ? parseInt(route.params.id) : 0"
      ></TagsList>
    </resizable-column>
    <resizable-column
      v-show="TagsDettaglisocial"
      :columnIndex="1"
      @column-resize="campaignColumnResize"
      ref="card"
      :style="cardStyle"
      :className="`flex-container flex-col layout-content w100 innovativestudio_colonne`"
    >
      <TagsTabs v-if="tag" :tag="tag" :key="tag.id"></TagsTabs>
      <ColumnPlaceHolder v-else></ColumnPlaceHolder>
    </resizable-column>
  </ResizableColumnContainer>

  <p v-else>il ruolo che stai impersonando non può vedere questa pagina</p>
</template>
<script>
import { useStore } from "vuex";
import { useRoute, onBeforeRouteLeave } from "vue-router";
import { computed, onMounted, ref } from "vue";
import { useCapabilities } from "../utils/Capabilities";
import ResizableColumn from "@/components/ResizableColumn";
import ResizableColumnContainer from "@/components/ResizableColumnContainer";
import TagsList from "@/components/tags/TagsList";
import TagsTabs from "@/components/tags/tabs/TagsTabs";
import ColumnPlaceHolder from "@/components/ColumnPlaceHolder";
import { apiTagFunction } from "@/api/tags";
import { onBeforeRouteUpdate } from "vue-router";
import {
  list,
  listStyle,
  card,
  cardStyle,
  onTotalSize,
  campaignColumnResize,
} from "@/utils/ColumnsResizer";

export default {
  data() {
    return {
      componentKey: 0,
      Tagsmostralista: true,
      TagsDettaglisocial: false,
    };
  },
  ////////////////////nascondo o attivo le div//////////////////
  created() {
    this.emitter.on("Tagsmostralista", (show) => {
      this.Tagsmostralista = show;
      this.componentKey += 1;
    });
    this.emitter.on("TagsDettaglisocial", (show) => {
      this.TagsDettaglisocial = show;
      this.componentKey += 1;
    });
  },

  components: {
    ResizableColumn,
    ResizableColumnContainer,
    TagsList,
    TagsTabs,
    ColumnPlaceHolder,
  },
  setup() {
    const store = useStore();
    const route = useRoute();

    const tags = computed(() => {
      return store.getters["tags/filtered"]
        ? store.getters["tags/filtered"]
        : store.getters["tags/paged"];
    });

    const social = computed(() => {
      return store.getters["social/social"];
    });
    const { canManageTags } = useCapabilities();

    const tag = ref(null);

    onMounted(async () => {
      if (route.params?.id) {
        tag.value = await apiTagFunction.show(route.params.id);
      } else {
        tag.value = null;
      }
    });

    onBeforeRouteLeave(async (to, from) => {
      const cardWidth = card?.value?.$el
        ? card.value.$el.getBoundingClientRect().width + "px"
        : "70%";

      if (list?.value?.$el) {
        store.commit("campaigns/savecolumnsizes", {
          listW: list.value.$el.getBoundingClientRect().width + "px",
          cardW: cardWidth,
        });
      }

      if (to.params.id && from.name !== "tag") {
        tag.value = await apiTagFunction.show(to.params.id);
      } else {
        tag.value = null;
      }
    });
    onBeforeRouteUpdate(async (to, from) => {
      if (to.params.id !== from.params.id) {
        tag.value = await apiTagFunction.show(to.params.id);
      } else if (!to.params.id) {
        tag.value = null;
      }
    });

    return {
      route,
      tags,
      social,
      canManageTags,
      campaignColumnResize,
      list,
      listStyle,
      card,
      cardStyle,
      onTotalSize,
      /* selectedTag, */
      tag,
    };
  },
};
</script>
