<template>
  <div class="layout-list-header">
    <span class="layout-list-header-title bebas f20 pl16">Target</span>
    <a href="#" class="btn-new" @click.prevent="creatorOpen = true">+</a>
    <CreateTagPanel
      v-if="creatorOpen"
      @close="creatorOpen = false"
    ></CreateTagPanel>
  </div>
  <ListHeader
    :defaultSort="defaultSort"
    @input="onInput"
    @change="onSort"
  ></ListHeader>
  <div class="layout-list-content infotronik_target_list">
    <ul class="layout-list nopadding nomargin">
      <TagListElement
        v-for="tag in tags"
        :key="tag.id"
        :tag="tag"
        @list-select="onTagSelect"
        :selectedId="selectedId"
        @click="openTag()"
      >
      </TagListElement>
    </ul>
  </div>
  <Pagination :pagination="pagination" @page-change="onPageChange"></Pagination>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import TagListElement from "@/components/tags/elements/TagListElement";
import CreateTagPanel from "@/components/tags/panels/CreateTagPanel";
import Pagination from "@/components/Pagination";
import ListHeader from "@/components/ListHeader";

export default {
  methods: {
    openTag() {
      /////emetto nascondendo o meno le colonne////
      this.emitter.emit("Tagsmostralista", false);
      this.emitter.emit("TagsDettaglisocial", true);
      // this.emitter.emit('TagsDettaglisocial2',true);

      ////////////breadcrumb//////////////
      //creo il breadcrumb per l'utente///
      this.routes = ref([
        //{ name: 'Home', path: '/' },
        { name: "Tags", path: "/tags" },
        //{ name: usern, path: '/user/'+userid }
      ]);
      ////////////breadcrumb//////////////
      //creo il breadcrumb per l'utente///
      this.emitter.emit("breadcrumb", this.routes);

      /////////////////////////////////////
    },
  },

  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    selectedId: {
      type: Number,
      default: null,
    },
    defaultSort: {
      type: String,
      default: "Nome asc",
    },
  },
  async mounted() {
    const defaultSorting = { type: "Nome asc" };
    this.onSort({ target: { value: defaultSorting } });
  },
  data() {
    return {
      selectedSort: this.defaultSort,
    };
  },
  components: {
    CreateTagPanel,
    TagListElement,
    ListHeader,
    Pagination,
  },
  setup() {
    const store = useStore();
    const creatorOpen = ref(false);

    const routes = ref([
      //{ name: 'Home', path: '/' },
      { name: "Tags", path: "/tags" },
      //{ name: usern, path: '/user/'+userid }
    ]);

    const onTagSelect = async (tag) => {
      await store.dispatch("social/bytag", { tags: [tag] });
      await store.dispatch("campaigns/bytag", { tags: [tag] });
    };

    const onInput = (filter) => {
      store.dispatch("tags/filter", filter);
    };

    const onSort = (sorting) => {
      store.dispatch("tags/sort", sorting);
    };

    const pagination = computed(() => {
      return store.getters["tags/pagination"];
    });

    const onPageChange = async (page) => {
      await store.dispatch("tags/getTags", { PageNumber: page });
      //await store.dispatch('tags/getSingleTag', props.selectedId)
    };

    return {
      onTagSelect,
      onInput,
      creatorOpen,
      onSort,
      pagination,
      onPageChange,
      routes,
    };
  },
};
</script>
