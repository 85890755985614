<template>
    <teleport to="#overlay">
        <div class="overlay w100 h100 abs">
            <div class="overlay-panel rel">
                <form class="flex-container flex-col h100" id="panel" @submit.prevent="onSubmit">
                    <div class="panel-header p24">
                        <div class="mini-logo"></div>
                        <h2 class="panel-title nomargin bebas">Crea Nuovo Target</h2>
                    </div>
                    <div class="flex-container flex-col scrollable-content pl24 pr24 pb24">
                        <div class="flex-container mb24">
                            <label class="flex-container flex-col">
                                <div class="flex-container heading-and-error">
                                <h3 class="bebas nomargin">Inserisci il Target</h3>
                                </div>
                                
                                <input  
                                    v-model="tagName" 
                                    type="text" 
                                    placeholder="Inserisci il Target"
                                    class="noborder nopadding pl8"
                                />
                            
                            </label>
                        </div>
                    </div>
                    <div class="flex-container w100 abs panel-footer">
                        <div class="flex-container">
                            <input type="reset" value="annulla" class="w100 noborder nopadding bebas" @click.prevent="emit('close')">
                            <input type="submit" value="Salva" class="w100 noborder nopadding bebas" :disabled="!tagName || tagName.length < 2">
                        </div>
                    </div>
                    
                </form>
                
            </div> 
         </div>
      
    </teleport>
</template>
<script>
import {ref} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {MESSAGE} from '@/store/VuexPaths'
export default {
    emits:['close'],
    setup(_, {emit}){
        const store = useStore()
        const tagName = ref(null)
        const router = useRouter()
        const onSubmit = async () =>{
            
            try {
                const response = await store.dispatch('tags/createTag', { tagName })
                emit('close')
                router.push('/tag/'+ response.id)
                await store.dispatch(MESSAGE, { title:'Target Creato', message:response.name + ' è stato creato correttamente' })
                window.location = "/tags";

            } catch (error) {
                
                await store.dispatch(MESSAGE, { title:'errore nella creazione del Target', message:error.message })
            }
        }
        return{
            tagName,
            emit,
            onSubmit
        }
    }
}
</script>