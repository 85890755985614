<template>
  <div class="flex-grow-1 rel">
    <div class="panel-header tab-header pl24 pr24 infotronik_target_header">
      <!--<div class="mini-logo "></div>-->
      <ul
        class="flex-container layout-list nopadding nomargin flex-end-align h100 infotronik_target_header_tabs"
      >
        <li class="layout-list-item noborder">
          <a
            href="#"
            :class="
              selected === 'tags'
                ? 'layout-list-item-btn pl16 pr24 selected infotronik_target_tabs_nomesel'
                : 'layout-list-item-btn pl16 pr24 infotronik_target_tabs_nomenotsel'
            "
            @click.prevent="onTabSelected('tags')"
          >
            <span class="pl8"><i class="lni lni-pagination"></i></span>
          </a>
        </li>
        <li class="layout-list-item">
          <a
            href="#"
            :class="
              selected === 'socials'
                ? 'layout-list-item-btn pl16 pr24 selected infotronik_target_tabs_socialsel'
                : 'layout-list-item-btn pl16 pr24 infotronik_target_tabs_socialnotsel'
            "
            @click.prevent="onTabSelected('socials')"
          >
            <span class="pl8"><i class="lni lni-control-panel"></i></span>
          </a>
        </li>
        <li class="layout-list-item">
          <a
            href="#"
            :class="
              selected === 'campaigns'
                ? 'layout-list-item-btn pl16 pr24 selected infotronik_target_tabs_campsel'
                : 'layout-list-item-btn pl16 pr24 infotronik_target_tabs_campnotsel'
            "
            @click.prevent="onTabSelected('campaigns')"
          >
            <span class="pl8"><i class="lni lni-network"></i></span>
          </a>
        </li>
      </ul>
    </div>
    <tag-card v-if="selected === 'tags'" :tag="tag" :key="tag.id"></tag-card>
    <tag-social
      v-else-if="selected === 'socials'"
      :tag="tag"
      :key="'s-' + route.fullPath"
    ></tag-social>
    <tag-campaign
      v-else-if="selected === 'campaigns'"
      :tag="tag"
      :key="'c-' + route.fullPath"
    ></tag-campaign>
  </div>
</template>
<script>
import { ref } from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import TagCard from "@/components/tags/tabs/TagCard";
import TagSocial from "@/components/tags/tabs/TagSocial.vue";
import TagCampaign from "@/components/tags/tabs/TagCampaign.vue";
export default {
  props: {
    selectedTab: {
      type: String,
      default: "tags",
    },
    tag: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    TagCard,
    TagSocial,
    TagCampaign,
  },
  setup(props) {
    const selected = ref(props.selectedTab);
    const router = useRouter();
    const route = useRoute();

    if (route.query.tab === undefined) {
      selected.value = "tags";
    } else {
      selected.value = route.query.tab;
    }

    const onTabSelected = (tab) => {
      selected.value = tab;
      router.push({ path: route.path, query: { tab: tab } });
    };

    onBeforeRouteUpdate(async (to, from) => {
      if (to.params.id === from.params.id && to.query !== from.query) {
        selected.value = to.query.tab || "tags";
      }
    });

    return {
      selected,
      onTabSelected,
      route,
    };
  },
};
</script>
