import { ApiEndPoints } from "@/api/Endpoints";
import { API } from "@/api/API";

export const show = async (id) => {
  const { data } = await API.get(ApiEndPoints.TAGS + "/" + id);
  return data;
};

export const loadTagData = async (id) => {
  const { data } = await API.get(ApiEndPoints.TAGS + "/" + id + "/editors");
  return data;
};

export const apiTagFunction = {
  show,
  loadTagData,
};
