<template>
  <li class="layout-list-item">
    <router-link
      :to="
        route.query.tab
          ? '/tag/' + tag.id + '?tab=' + route.query.tab
          : '/tag/' + tag.id
      "
      :class="
        selected
          ? 'layout-list-item-btn flex-container p8 pl16 selected'
          : 'layout-list-item-btn flex-container p8 pl16'
      "
      :key="route.fullPath"
    >
      <div class="layout-list-item-title nomargin">{{ tag.name }}</div>
    </router-link>
  </li>
</template>
<script>
import { computed } from "vue";
import { useRoute } from "vue-router";
export default {
  emits: ["list-select"],
  props: {
    tag: {
      type: Object,
      default: () => {},
    },
    selectedId: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const route = useRoute();
    const selected = computed(() => {
      return props.selectedId === props.tag.id;
    });
    return {
      selected,
      route,
    };
  },
};
</script>
